<template>
  <div class="exam-list">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="exam-list-cont">
      <TabCategory @changeTab="changeTab" :curTabIdP="curTabId" :dataList="tabList"></TabCategory>
      <div>
        <examList :type="curTab" :fromType="fromType" :generateDataRule="2" :categoryType="curTabId"></examList>
<!--        <materialList v-if="curTabId == '0'"></materialList>-->
      </div>
    </div>

  </div>
</template>

<script>
import TabCategory from '@/components/tabCategory';
import examList from './components/exam/list'
// import materialList from './components/myCategory/materialList'
export default {
  name: "examListView",
  data(){
    return{
      curTabId:'4',

      tabList:[
        {
          name:'课程',
          id:'4',
          tab:'course',
          type:1,
          from_type:'5'
        },
        {
          name:'认证题库',
          id:'0',
          tab:'attestation',
          type:1
        },
        // {
        //   name:'教材题库',
        //   id:'1',
        //   tab:'material',
        //   type:1
        // },
      ],
      curTab:1,
      fromType:'5',
    }
  },
  components:{
    examList,
    TabCategory,
    // materialList
  },
  created(){
    let tab = this.$route.query.tab || '';

    if(tab){
      let curData = this.tabList.filter((item)=>{
        return item.tab == tab;
      })[0];
      this.curTab = curData.type;
      this.curTabId = curData.id;
      this.fromType = curData.from_type || '';
    }
  },
  methods:{
    changeTab(data){
      this.curTabId = data.id;
      this.curTab = data.type || 1;
      this.fromType = data.from_type || '';
      this.$router.push({
        path:'/trainingcamp/examlist',
        query:{
          tab:this.curTab.tab
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exam-list{
  margin: 20px;
  .exam-list-cont{
    padding: 20px;
    background-color: #FFFFFF;
  }
}
</style>
