<template>
  <div class="data-list">
    <div class="table">
      <Table ref="selection" border :columns="columns" :data="dataList" :loading="tableLoading" ></Table>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataList",
  data(){
    return {
      columns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
        },
        {
          title: this.$t('trainingcamp_exam_create_category_name'),
          key: 'name'
        },
        {
          title: this.$t('trainingcamp_exam_create_category_count'),
          key: 'data_count'
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.create(params.row)
                  }
                }
              }, '创建'),
            ]);
          }
        }
      ],
      dataList: [
      ],
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      selectDataList:[],
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      tableLoading:false,
      secondLoading:false,
    }
  },
  props:{
    selectData:{
      type:Array,
      default:()=>{
        return [];
      },
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  watch:{
    selectData:{
      handler(newV){
        if(newV.length){
          this.selectDataList = newV;
        }
      },
      immediate:true,
    }
  },
  created(){
    this.getList();
  },
  methods:{

    getList(){
      this.tableLoading = true;
      this.api.dataset.datasetGroupForm().then((res)=>{
        this.tableLoading = false;
        this.dataList = res.group_list;
      }).catch((err)=>{
        this.tableLoading = false;
      })
    },
    create(data){
      this.$emit('create',data.id)
    },
    // selectDataFn(data){
    //   this.selectDataList = data;
    //   this.$emit('changeData',this.selectDataList)
    // },

  }
}
</script>

<style scoped lang="scss">
.data-list{
  margin-top: 20px;
  .table{
    height: 500px;
    overflow-y: auto;
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}

</style>
