<template>
  <div class="role-main">
<!--    <MyBreadcrumb></MyBreadcrumb>-->
    <div class="role-main-cont">
      <div class="exam-create-top">
        <Button type="primary" @click="add('1')" style="margin-right: 10px" v-if="categoryType == 1">{{addMateralMessage}}</Button>
        <Button type="primary" @click="add('0')" v-if="categoryType == 0">{{addMessage}}</Button>
        <Button type="primary" @click="add('4')" v-if="categoryType == 4">创建考试</Button>
      </div>

      <Table border :columns="columns" :loading="loading" :data="data">

      </Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :page-size="pageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
    </div>

    <DeleModal :status="deleModalStatus" :txt="$t('dataset_exam_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

<!--    <Modal-->
<!--        v-model="modalStatus"-->
<!--        :title="modalTit"-->
<!--        @on-cancel="cancel"-->
<!--    >-->
<!--      <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">-->
<!--        <FormItem :label="$t('dataset_exam_table_name')" prop="name">-->
<!--          <Input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('dataset_exam_add_name_tip')"></Input>-->
<!--        </FormItem>-->
<!--        <FormItem :label="$t('dataset_exam_table_data_count')" prop="data_count">-->
<!--          <Input :disabled="type == 'check'" v-model="formItem.data_count" :placeholder="$t('dataset_exam_add_data_count_tip')"></Input>-->
<!--        </FormItem>-->
<!--        <FormItem :label="$t('dataset_exam_table_desc')" prop="desc">-->
<!--          <Input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('dataset_exam_add_desc_tip')"></Input>-->
<!--        </FormItem>-->

<!--      </Form>-->
<!--      <div slot="footer">-->
<!--        <Button @click="cancel">取消</Button>-->
<!--        <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>-->
<!--      </div>-->
<!--    </Modal>-->
    <Modal
        v-model="selectDataModal"
        :title="$t('project_select_type')"
        :footer-hide="true"
        :scrollable="true"
        class="select-data-modal"
        width="1000px"
    >
      <div class="tab-list">
        <div class="tab-list-top">
          <div>
            <div :class="selectDataTabList[curSelectDataTabIndex].id == item.id ? 'active' : '' " v-for="(item,index) in selectDataTabList" :key="item.id" @click="changeTab(index)">{{item.name}}</div>
          </div>

        </div>
        <div class="select-data-cont">
<!--          <CategoryData></CategoryData>-->
          <component :is="selectDataTabList[curSelectDataTabIndex].componentName"  @create="createExam"></component>
        </div>
      </div>
    </Modal>

    <el-drawer :visible.sync='invigilationDrawer' :size="800" @close="invigilationDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>考试监考</div>
        <div class='drawer-title-btns'>
          <el-button type="primary" size="small" @click="createInvigilation()" :loading="invigilationLoading">确定</el-button>
        </div>
      </div>
      <div style='padding:20px;'>
        <el-form ref="invRef" :model="invigilationForm" :rules="invigilationRules" label-width="100px">
          <el-form-item label="监考名称" prop="name">
            <el-input type="textarea" v-model="invigilationForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer :visible.sync='courseListDrawer' :size="800">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>选择课程</div>
      </div>
      <div style='padding:20px;'>
        <Table border :columns="courseColumns" :loading="loading" :data="dataList"></Table>
        <div class="page" v-if="dataList.length">
          <MyPage :total="courseTotal" :current="coursePage" @changePage="changeCoursePage" @changePageSize="changeCoursePageSize"></MyPage>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
// import DataSource from './dataSource';
import CategoryData from "../common/categoryData"
import util from '@/utils/tools.js';
import GroupData from '../common/groupData'
export default {
  name: "examListComp",
  data(){
    return{
      invigilationDrawer:false,
      invigilationForm:{
        name:"",
        from_id:"",
        from_type:"2"
      },
      invigilationLoading:false,
      invigilationRules:{
        name:[
          {required:true,message:"请输入监考名称",trigger:"blur"}
        ]
      },
      columns: [
        {
          title: this.$t('dataset_exam_table_index'),
          key:'id',
          width:80,
        },
        {
          title: this.$t('dataset_exam_table_title'),
          key: 'name',
          minWidth:200,
          render:(h,params)=>{
            return util.tableColumnTooltip(h,params.row.name,{path:this.linkName,query:{id:params.row.id,tab:"explain"}});
          }

          // render: (h, params) => {
          //   return h('router-link', {
          //     props: {
          //       to: {
          //         path: this.linkName,
          //         query: {
          //           id: params.row.id,
          //           tab: 'explain'
          //         }
          //       }
          //     },
          //     style:{
          //       color:'#2db7f5',
          //     }
          //   }, params.row.name);
          // }
        },
        // {
        //   title: this.$t('trainingcamp_exam_list_generate_type'),
        //   key: 'generate_type',
        //   render: (h, params) => {
        //     return h('span', this.generatetypesObj[params.row.generate_type]);
        //   }
        // },
        {
          // title: this.$t('trainingcamp_exam_list_generate_id'),
          title:'教材/分类',
          key: 'generate_type',
          minWidth:200,
          renderHeader: (h, params) => {
            let name = '';
            if(this.fromType == 5){
              name = '课程';
            }else{
              if(this.categoryType == '0'){
                name = '认证';
              }else if(this.categoryType == '1'){
                name = '教材';
              }

            }
            return h('div',{},name);
          },
          render: (h, params) => {
            if (params.row.generate_type == 0){
              if(params.row.from_type == 5){
                // return h('span', params.row.generate_course && params.row.generate_course.name);
                return util.tableColumnTooltip(h,params.row.generate_course && params.row.generate_course.name);
              }else{
                // return h('span', params.row.generate_category.name);
                return util.tableColumnTooltip(h,params.row.generate_category.name);
              }

            }else if (params.row.generate_type == 1){
              // return h('span', params.row.generate_map.name);
              return util.tableColumnTooltip(h,params.row.generate_map.name);
            }else if (params.row.generate_type == 2){
              // return h('span', params.row.generate_group.name);
              return util.tableColumnTooltip(h,params.row.generate_group.name);
            }
          }
        },
        {
          title: this.$t('trainingcamp_exam_list_data_cont'),
          key: 'data_count',
          minWidth:100,
        },
        {
          title: this.$t('trainingcamp_exam_list_use_time'),
          key: 'timelong',
          minWidth:100,
        },
        {
          title: this.$t('dataset_exam_table_total_franction'),
          key: 'total_score',
          minWidth:100,
        },
        {
          title: this.$t('dataset_exam_table_pass_score'),
          key: 'pass_score',
          minWidth:100,
        },
        {
          title: this.$t('dataset_exam_table_dnum'),
          key: 'join_user_count',
          minWidth:100,
        },
        {
          title: this.$t('trainingcamp_exam_list_create_user'),
          key: 'user.nickname',
          minWidth:100,
          render(h,params){
            return h('span',params.row.user.nickname)
          }

        },
        {
          title: this.$t('dataset_exam_table_status'),
          minWidth:100,
          render: (h, params) => {
            return h('span', this.statusObj[params.row.status]);
          }
        },
        {
          title: this.$t('trainingcamp_exam_list_start_time'),
          key: 'created_at',
          minWidth:150,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.start_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('trainingcamp_exam_list_end_time'),
          key: 'updated_at',
          minWidth:150,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.end_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_exam_table_op'),
          width:310,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
                params.row.status != 0 ? h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  style: {
                    marginRight: '5px',
                    marginTop: '5px',
                  },
                  on: {
                    click: () => {
                      this.paperAnalysis(params.row)
                    }
                  }
                }, '考试结果和分析') : '',
                (params.row.status == 2 || params.row.status == 3) ? h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  style: {
                    marginRight: '5px',
                    marginTop: '5px',
                  },
                  on: {
                    click: () => {
                      this.check(params.row)
                    }
                  }
                }, this.$t('system_check')) : h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  style: {
                    marginRight: '5px',
                    marginTop: '5px',
                  },
                  on: {
                    click: () => {
                      this.edit(params.row)
                    }
                  }
                }, this.$t('system_edit')),
                params.row.is_open_monitor==="1" && this.userSetting.open_webrtc==="1"?h("Button",{
                  props:{
                    size:"small",
                    type:"primary"
                  },
                  style:{
                    marginRight:"5px",
                    marginTop:"5px"
                  },
                  on:{
                    click:()=>{
                      this.invigilation(params.row)
                    }
                  }
                },"进入监考"):'',
                h('Button', {
                  props: {
                    size: 'small',
                    type:"error"
                  },
                  style: {
                    marginRight: '5px',
                    marginTop: '5px',
                  },
                  on: {
                    click: () => {
                      this.delete(params.row);
                    }
                  }
                }, '删除'),
              (params.row.status == 2 || params.row.status == 3) ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px',
                },
                on: {
                  click: () => {
                    this.copy(params.row)
                  }
                }
              }, '复制') : '',
              // h('Select', {
              //       props:{
              //         transfer: true,//不受表格高度影响,
              //         placeholder:this.$t('trainingcamp_exam_list_more'),
              //         size: 'small',
              //         clearable:true,
              //       },
              //       style:{
              //         width:'60px'
              //       },
              //       on: {
              //         'on-change':(event) => {
              //           if(event == 'answer'){  //尝试作答
              //             // this.stop(params.row);
              //           }else if(event == 'copy'){  //复制
              //             // this.delete(params.row)
              //           }else if(event == 'dele'){  //删除
              //             // this.delete(params.row)
              //             this.delete(params.row);
              //           }
              //         }
              //       },
              //     },
              //     [
              //       // h('Option',{
              //       //   props: {
              //       //     value: 'answer'
              //       //   }
              //       // },this.$t('trainingcamp_exam_list_try_answer')),
              //       // h('Option',{
              //       //   props: {
              //       //     value: 'copy'
              //       //   }
              //       // },this.$t('trainingcamp_exam_list_copy')),
              //       h('Option',{
              //         props: {
              //           value: 'dele'
              //         }
              //       },this.$t('trainingcamp_exam_list_delete'))
              //     ])
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        id:'',
        name:'',
        data_count:'',
        desc:'',
      },
      // ruleValidate: {
      //   id: [
      //     { required: true, message: this.$t('dataset_exam_add_id_tip'), trigger: 'blur' }
      //   ],
      //   name: [
      //     { required: true, message: this.$t('dataset_exam_add_name_tip'), trigger: 'blur' }
      //   ],
      //   data_count: [
      //     { message: this.$t('dataset_exam_add_data_count_tip'), trigger: 'blur' }
      //   ],
      //   desc: [
      //     { message: this.$t('dataset_exam_add_desc_tip'), trigger: 'blur' },
      //   ],
      // },
      addBtnLoading:false,
      // type:'',
      modalTit:'',
      typesObj:{},
      levelsObj:{},
      statusObj:{},
      generatetypesObj:{},
      selectDataModal:false,
      selectDataTabList:[ //0分类1知识图谱2产品3题库
        {
          name:'分类',
          id:0,
          componentName:'CategoryData'
        },
        // {
        //   name:'题库',
        //   id:2,
        //   componentName:'GroupData'
        // },
        // {
        //   name:'知识图谱',
        //   id:1
        // },
      ],
      curSelectDataTabIndex:0,
      path:'/trainingcamp/materialCreate',
      linkName:'/trainingcamp/teacher/exam-detail',
      addMessage:this.$t('dataset_exam_add'),
      addMateralMessage:'创建教材模考',
      dataList:[],
      courseColumns: [
        {
          title: '课程名称',
          key: 'name',
          tooltip:true,
        },
        {
          title: '类型',
          key: 'name',
          width:100,
          render: (h, params) => {
            return h('span', params.row.is_private == 1 ? '组织课程' : '平台课程');
          }
        },
        {
          title: '更新时间',
          key: 'id',
          width:150,
          render: (h, params) => {
            // return h('span', util.timeFormatter(new Date((+params.row.created_at || +params.row.updated_at) * 1000), 'yyyy-MM-dd hh:mm'));
            return util.tableColumnTooltip(h,util.timeFormatter(new Date((+params.row.created_at || +params.row.updated_at) * 1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          key: 'id',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.goMaterialCreate(params.row,1,2)
                  }
                }
              }, '创建课程'),
            ]);
          }
        },
      ],
      courseTotal:0,
      coursePage:1,
      coursePageSize:10,
      courseListDrawer:false,
    }
  },
  props:{
    type:{
      type:Number,
      default:0,
    },
    generateDataRule:{
      type:Number,
      default:0,
    },
    categoryType:{
      type:String,
      default:'0',
    },
    fromType:{
      type:String,
      default:'',
    }
  },
  computed:{
    userSetting(){
      return this.$store.state.user.userSetting || {};
    }
  },
  watch:{
    categoryType:{
      handler(newV){
        console.log(newV,'newVnewVnewVnewV')
        if(newV){
          this.getList();
        }
      },
      immediate:true
    }
  },
  created(){
    if(this.type == 1){
      // this.path = '/trainingcamp/exam-create';
      this.linkName = '/trainingcamp/teacher/exam-detail';
      this.addMessage = this.$t('dataset_exam_add');
      this.addMateralMessage = '创建教材模考';
    }else if(this.type == 2){
      // this.path = '/trainingcamp/homework-create';
      this.linkName = '/trainingcamp/teacher/homework-detail';
      this.addMessage = this.$t('dataset_homework_add');
      this.addMateralMessage = '创建教材课后练习';
    }else if(this.type == 3){
      this.path = '/trainingcamp/match-create';
      this.linkName = '/trainingcamp/matchDetail';
      this.addMessage = this.$t('dataset_match_add');
      this.addMateralMessage = '创建教材PK竞赛';
    }
  },
  components:{
    DeleModal,
    CategoryData,
    GroupData
  },
  mounted(){

  },
  methods:{
    changeCoursePage(page) {
      this.coursePage = page;
      this.getCourseList();
    },
    changeCoursePageSize(pageSize) {
      this.coursePageSize = pageSize;
      this.coursePage = 1;
      this.getCourseList();
    },
    getCourseList() {
      let params = {
        page: this.coursePage,
        pageSize: this.coursePageSize,
        user_id:'-1',
        status:0
      };
      this.api.course.courseList(params).then((res) => {
        this.dataList = res.list;
        this.courseTotal = Number(res.count);
      })
    },
    goMaterialCreate(data,type,generateDataRule){
      let params = {
        op:'create',
        generate_type:0,
        generate_id:data.id,
        type:1,
        from_type:5,
        from_id:data.id,
        generate_data_rule:2,
      };
      this.api.dataset.trainPublish(params).then((res)=>{
        this.$router.push({
          path:'/trainingcamp/materialCreate',
          query:{
            trainId:res.train_id,
            mapId:data.map_id,
            createType:3  //1 教材 2 认证 3 课程
          }
        })
      })
    },
    add(id){
     if(id == 1){
        this.$router.push({
          path:'/trainingcamp/bookmanage',
        })
      }else if(id == 4){  //课程
        // this.$router.push({
        //   path:'/course/teacher-list',
        // })
       this.courseListDrawer = true;
       this.getCourseList();
      }else{
        this.$router.push({
          path:'/trainingcamp/certmanage',
        })
      }

      // this.selectDataModal = true
    },
    createExam(data){
      console.log('2222')
      let params = {
        op:'create',
        generate_type:this.selectDataTabList[this.curSelectDataTabIndex].id,
        generate_id:data,
        // generate_data_rule:this.generateDataRule,//组卷模考
        type:this.type //0练习1考试2课后作业
      };
      if(this.generateDataRule >= 0){
        params.generate_data_rule = this.generateDataRule;
      }
      this.api.dataset.trainPublish(params).then((res)=>{
        this.$router.push({
          path:this.path,
          query:{
            trainId:res.train_id,
            createType:this.type
          }
        })
      })

    },
    check(data){
      if(this.fromType == 5){
        this.$router.push({
          path:this.path,
          query:{
            trainId:data.id,
            mapId:data.generate_course.map_id,
            type:'check',
            createType:this.type
          }
        })
      }else{
        if(this.categoryType == 0){
          this.$router.push({
            path:this.path,
            query:{
              trainId:data.id,
              type:'check',
              createType:this.type
            }
          })
        }else{
          this.$router.push({
            path:this.path,
            query:{
              trainId:data.id,
              mapId:data.generate_category.map_id,
              type:'check',
              createType:this.type
            }
          })
        }

      }

    },
    edit(data){
      console.log(this.path,'this.paththis.path')
      if(this.fromType == 5){
        this.$router.push({
          path:this.path,
          query:{
            trainId:data.id,
            mapId:data.generate_course.map_id,
            type:'edit',
            createType:this.type
          }
        })
      }else{
        if(this.categoryType == 0){
          this.$router.push({
            path:this.path,
            query:{
              trainId:data.id,
              type:'edit',
              createType:this.type
            }
          })
        }else{
          this.$router.push({
            path:this.path,
            query:{
              trainId:data.id,
              mapId:data.generate_category.map_id,
              type:'edit',
              createType:this.type
            }
          })
        }

      }
    },
    getList(){
      this.loading = true;
      let params = {
        type:this.type,//0练习1考试2课后作业
        page:this.page,
        pageSize:this.pageSize,
        category_type:this.categoryType,
      };
      if(this.fromType){
        params.from_type = this.fromType;
      }
      this.api.dataset.trainList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);

        this.typesObj = res.types;
        this.levelsObj = res.levels;
        this.statusObj = res.statuses;
        this.generatetypesObj = res.generate_types;

      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curDeleData.id
      }
      this.modalLoading = true;
      this.api.dataset.trainDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(size){
      this.pageSize=size;
      this.getList();
    },
    // ok(){
    //   this.$refs.formCustom.validate((valid) => {
    //     if (valid) {
    //       let data = {
    //         id:this.formItem.id,
    //         data_count:this.formItem.data_count,
    //         name:this.formItem.name,
    //         desc:this.formItem.desc
    //       };
    //       this.addBtnLoading = true;
    //       if(this.formItem.id){
    //         this.api.dataset.trainUpdate(data).then((res)=>{
    //           this.addBtnLoading = false;
    //           this.modalStatus = false;
    //           this.getList();
    //         }).catch((err)=>{
    //           this.addBtnLoading = false;
    //         })
    //       }else{
    //         this.api.dataset.trainCreate(data).then((res)=>{
    //           this.addBtnLoading = false;
    //           this.modalStatus = false;
    //           this.page = 1;
    //           this.getList();
    //         }).catch((err)=>{
    //           this.addBtnLoading = false;
    //         })
    //       }
    //
    //     }else{
    //       console.log(this.modalStatus)
    //     }
    //   })
    // },
    // cancel(){
    //   this.modalStatus = false;
    //   this.$refs.formCustom.resetFields();
    // },
    changeTab(index){
      this.curSelectDataTabIndex = index;
    },
    paperAnalysis(data){
      this.$router.push({
        path:'/trainingcamp/paperAnalysis',
        query:{
          id:data.id
        }
      })
    },
    invigilation(row){
      // const { invigilationForm }=this;
      // invigilationForm.name=row.name;
      // invigilationForm.from_id=row.id;
      // this.invigilationDrawer=true;
      // this.api.live.createLiveRoom(this.invigilationForm).then((res)=>{
      //   this.invigilationLoading=false;

      // });
      this.$router.push({
        path:"/liveroom-invigilation-management",
        query:{
          examId:row.id,
          examName:row.name
        }
      });
    },
    invigilationDrawerClose(){
      const { invigilationForm }=this;
      invigilationForm.name='';
      this.$refs.invRef.resetFields();
      this.invigilationDrawer=false;
    },
    createInvigilation(){
      this.invigilationLoading=true;
      this.$refs.invRef.validate(valid=>{
        if(valid){
          this.api.live.createLiveRoom(this.invigilationForm).then((res)=>{
            this.invigilationLoading=false;
            this.$router.push({
              path:"/liveroom-invigilation-management",
              query:{
                roomId:res.info.id
              }
            });
          }).catch(()=>{
            this.invigilationLoading=false;
          });
        }else{
          this.invigilationLoading=false;
        }
      });
    },
    copy(data){
      let params = {
        source_train_id:data.id,
      }
      this.api.dataset.trainCopy(params).then((res)=>{


        if(this.fromType == 5){
          this.$router.push({
            path:this.path,
            query:{
              trainId:res.info.id,
              mapId:data.generate_course.map_id,
              type:'edit',
              createType:this.type
            }
          })
        }else{
          if(this.categoryType == 0){
            this.$router.push({
              path:this.path,
              query:{
                trainId:res.info.id,
                type:'edit',
                createType:this.type
              }
            })
          }else{
            this.$router.push({
              path:this.path,
              query:{
                trainId:res.info.id,
                mapId:data.generate_category.map_id,
                type:'edit',
                createType:this.type
              }
            })
          }

        }

      })
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  //margin: 20px;

  .role-main-cont{
    //margin-top: 20px;
    //padding:20px;
    //background: #FFFFFF;
    //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    //border-radius: 4px;
  }
  .exam-create-top{
    margin-top: 20px;
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.select-data-modal {
  .ivu-modal {
    width: 75% !important;
    min-width: 480px;
    max-width: 960px;
  }

  .tab-list {
    padding: 0 15px;

    .tab-list-top {
      display: inline-block;
      >div{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;

        >div {
          width: 80px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-right: 1px solid #dcdee2;
          cursor: pointer;
          font-size: 14px;
        }
        >div:last-child{
          border-right: none;
        }

        > div.active {
          color: #FFFFFF;
          background: #2d8cf0;
        }
      }
    }

    .category-list-cont {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;


    }
  }
}
</style>
